import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", {
    attrs: {
      "title": "Add Warehouses"
    }
  }, [_c("text-input", {
    key: "code",
    attrs: {
      "form-item": "",
      "label": "Warehouse",
      "placeholder": "Type Warehouse Code",
      "span": 24,
      "max-length": 2,
      "rules": _vm.ruleSpecialCharacter
    }
  }), _c("text-input", {
    key: "description",
    attrs: {
      "label": "Warehouse Description",
      "placeholder": "Type Description",
      "span": 24,
      "max-length": 30
    }
  }), _c("switch-input", {
    key: "offsiteFlag",
    attrs: {
      "label": "Offsite Flag",
      "span": 12
    }
  }), _c("text-input", {
    key: "printer",
    attrs: {
      "label": "Printer",
      "placeholder": "Type Printer",
      "span": 12
    }
  }), _c("text-input", {
    key: "asnEmail",
    attrs: {
      "label": "ASN Email",
      "placeholder": "Type Email",
      "span": 24,
      "max-length": 100
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateWarehouses",
  data() {
    return {
      ruleSpecialCharacter: {
        required: true,
        regexCustom: {
          regex: /^[A-Za-z0-9 ]+$/,
          message: "must not contain special character"
        }
      }
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateWarehouses = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "create-ware-houses"
  }, [_c("resource", {
    attrs: {
      "name": "grower.ware-houses",
      "api-url": _vm.apiUrl,
      "create": _vm.CreateWarehouses,
      "redirect-route": "/watties-grower/ware-houses"
    }
  })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateWarehouses",
  data() {
    return {
      CreateWarehouses,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
